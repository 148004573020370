import React, { useState, useEffect } from 'react'
import "./HomeCreators.scss"
import { useInView } from 'react-intersection-observer'
import { HomeWorkCard } from '../../organism/HomeWorkCard'
import { GatsbyImage } from 'gatsby-plugin-image'

const HomeCreators = ({ data }) => {
  
  const [workCounter, setWorkCounter] = useState(4);
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });


  return (
    <section ref={ref} id="creators" className={`animated-block ${inView ? "active" : ""}`}>
      <div id="block-creators" className="block-creators inner-page">
        <div className="inner-wrap">
          <div className="content">
            <div className="text_content">
              <h3 className="tt-3 only_mobile" dangerouslySetInnerHTML={{ __html: data.title }} />
              <div className="description">
                <h3 className="tt-3 only_desktop" dangerouslySetInnerHTML={{ __html: data.title }} />
                <div dangerouslySetInnerHTML={{ __html: data.description.childMarkdownRemark.html }} />
              </div>
            </div>
            <div className="visuel">
              <GatsbyImage image={data.titleImageDesktop.gatsbyImageData} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default HomeCreators