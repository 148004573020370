import React from 'react'
import "./HomeLive.scss"
import { useInView } from 'react-intersection-observer'
import { GatsbyImage } from 'gatsby-plugin-image'

const HomeLive = ({data}) => {
    const { ref, inView, entry } = useInView({
        threshold: 0.5,
    });

    console.log(data.titleImageDesktop.gatsbyImageData);

    const divStyle = {
        background: 'url('+data.titleImageDesktop.file.url+') 0 50% no-repeat',
    }

    return (
        <section ref={ref} id="live" className={`animated-block ${inView ? "active" : ""}`}>
            <div className="block-live inner-page">
                <div className="inner-wrap">
                    <div className="title" style={divStyle}>
                        <div className="tt-2">
                            <GatsbyImage image={data.titleImageMobile.gatsbyImageData} alt=""/>
                        </div>
                    </div>
                    <div className="content">
                        <div className="post-wrap post-wrap-1">
                            <div className="post type-1">
                                <div className="txt">
                                    <GatsbyImage image={data.image1Text.gatsbyImageData} alt=""/>
                                </div>
                                <div className="image">
                                    <GatsbyImage image={data.image2Photo.gatsbyImageData} alt=""/>
                                </div>
                            </div>
                            <div className="post type-2">
                                <div className="txt">
                                    <GatsbyImage image={data.image3Text.gatsbyImageData} alt=""/>
                                </div>
                                <div className="image">
                                    <GatsbyImage image={data.image4Photo.gatsbyImageData} alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className="post-wrap post-wrap-2">
                            <div className="post type-3">
                                <div className="txt">
                                    <GatsbyImage image={data.image5Text.gatsbyImageData} alt=""/>
                                </div>
                                <div className="image">
                                    <GatsbyImage image={data.image6Photo.gatsbyImageData} alt=""/>
                                </div>
                            </div>
                            <div className="post type-4">
                                <div className="image">
                                    <GatsbyImage image={data.image8Photo.gatsbyImageData} alt=""/>
                                </div>

                                <div className="txt">
                                    <GatsbyImage image={data.image7Text.gatsbyImageData} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeLive